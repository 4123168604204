import AttributesSection from '../components/sections/AttributesSection'
import BaseLayout from '../components/layout/BaseLayout'
import CallToAction from '../components/elements/CallToAction'
import Card from '../components/elements/cards/Card'
import Col from 'react-bootstrap/Col'
import ExternalLink from '../components/elements/links/ExternalLink'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../components/layout/Section'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import theme from '../theme'
import makeSeoTitle from '../lib/utils/makeSeoTitle'
//import whitePattern from '../assets/img/misc/pattern_w.jpg'

const AVATAR_SIZE = 140

const logo = css`
  margin: auto;
  border-radius: 50%;
  height: ${AVATAR_SIZE}px;
  width: ${AVATAR_SIZE}px;
  transform: translate(0px, ${AVATAR_SIZE / 2}px);
  position: relative;
  z-index: 10;
`

const aboutCard = css`
  padding-top: ${AVATAR_SIZE / 2 + 20}px;

  p {
    color: ${theme.palette.darkGrey};
  }

  a {
    color: ${theme.palette.blue};
    text-decoration: none;
  }
`

const AboutPage = () => (
  <BaseLayout>
    <Section>
      <Row>
        <Col md={6} className="vertical-center">
          <h1>Über EisDesigns</h1>
          <p>
            Ich bin Grafikdesigner und nun seit mehreren Jahren selbständig.
            Zuvor habe ich eine schulische Ausbildung zum Grafikdesigner
            absolviert. Während dieser Zeit habe ich viel gelernt und auch erste
            Erfahrungen in der Branche gesammelt. Gegen Ende meiner Ausbildung
            beschloss ich mich selbstständig zu machen.
          </p>
          <p>
            Seitdem realisiere ich mit verschiedenen Kunden aufregende
            Designprojekte. Erfahren Sie im Folgenden mehr über mich und wie es
            dazu kam.
          </p>
        </Col>
        <Col md={6}>
          <StaticImage
            src="../assets/img/pages/about/book.png"
            quality={100}
            placeholder="blurred"
            alt="Buch"
          />
        </Col>
      </Row>
    </Section>

    <AttributesSection />

    <Section
      //backgroundImage={whitePattern}
      css={css`
        background-size: 10%;
      `}
    >
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div css={logo}>
            <StaticImage
              src="../assets/img/pages/about/me.png"
              quality={100}
              placeholder="blurred"
              alt="Bild von Tim Trietsch"
            />
          </div>

          <Card css={aboutCard}>
            <h1
              css={css`
                font-size: 1.5rem;
                font-weight: 700;
              `}
            >
              Über mich
            </h1>
            <p>
              Zunächst einmal freue ich mich sehr, Sie auf meiner Website
              begrüßen zu dürfen. Mein Name ist Tim Trietsch und ich bin ein
              kreativer Grafikdesigner aus Heidelberg. Nach meinem
              Schulabschluss habe ich eine dreijährige Ausbildung zum
              Grafikdesigner begonnen, die ich erfolgreich abgeschlossen habe.
              Im letzten Jahr dieser Ausbildung habe ich mich 2019 selbstständig
              gemacht und bin seitdem als freiberuflicher Designer tätig.
            </p>
            <p>
              Mit viel Kreativität und Leidenschaft gestalte ich für meine
              Kunden verschiedenste Projekte - von Corporate Identity über
              Printmedien bis hin zu digitalen Inhalten für Social Media. Dabei
              lege ich stets Wert auf ein individuelles und modernes Design, das
              den Wünschen und Bedürfnissen meiner Kunden entspricht.
            </p>
            <p>
              Im Rahmen von Beratungen kann ich oft schon in der
              Konzeptionsphase Ideen und Vorschläge einbringen, die dann in die
              konkrete Umsetzung einfließen. Da mir eine gute und effiziente
              Kommunikation zwischen mir und meinen Kunden wichtig ist, habe ich
              mich von Anfang an dafür entschieden, auf umständliche
              Formalitäten möglichst zu verzichten. Daher biete ich meinen
              Kunden einfache und formlose Kontaktmöglichkeiten über{' '}
              <ExternalLink to="https://wa.me/491785088629">
                WhatsApp
              </ExternalLink>{' '}
              oder{' '}
              <ExternalLink to="https://discord.gg/mAmPdwKWrf">
                Discord
              </ExternalLink>{' '}
              an, um mit mir in Verbindung zu treten. Daher freue ich mich
              darauf, Sie bei Problemen oder Fragen in einem beratenden Gespräch
              begrüßen zu dürfen.
            </p>

            <p>
              Mit freundlichen Grüßen,
              <br />
              Tim Trietsch
            </p>
          </Card>
        </Col>
      </Row>
    </Section>

    <CallToAction buttonLink="/kontakt/" buttonText="Kontakt">
      Lassen Sie uns zusammen eine schönere Zukunft gestalten!
    </CallToAction>
  </BaseLayout>
)

export const Head = () => (
  <>
    <title>{makeSeoTitle('Über mich')}</title>
    <meta
      name="description"
      content="EisDesigns bietet eine Vielzahl verschiedener Design-Lösungen für dein Projekt. Beauftrage mich und erwecke deine Vision zum Leben!"
    />
  </>
)

export default AboutPage
